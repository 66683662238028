/* eslint-disable vue/no-v-html */
<template>
  <div id="app">
    <loading v-if="isUserLoading">
      Загрузка данных пользователя
    </loading>
    <div
      v-else
      class="h-100"
    >
      <div class="d-flex h-100">
        <Navbar v-if="isLogged" />
        <router-view :key="$route.path" />
      </div>
      <TabPanel />
    </div>
    <vuedal />
    <modals />

    <Chat
      v-if="isUserRoleAndLogged"
      :number-of-appeals="numberOfAppeals"
      @set-count="setAmount"
      @remove-appeal="removeAppeal"
      @chat-is-open="setChatState"
    />
    <notifications
      :max="5"
      :width="'50%'"
      group="global-notifications"
      position="top center"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <div
          :class="{
            [props.item.type]: true
          }"
          class="vue-notify"
        >
          <div
            v-if="props.item.title"
            class="vue-notify__title"
          >
            {{ props.item.title }}
          </div>

          <div
            v-if="props.item.text"
            class="vue-notify__content"
            v-html="props.item.text"
          />

          <span
            class="vue-notify__close-link"
            @click="props.close"
          >
            &times;
          </span>
        </div>
      </template>
    </notifications>

    <Call v-if="!isUserLoading && isLogged" />

    <common-modal-container />
  </div>
</template>

<script>
import { mixinModals } from '@/mixins/mixinModals';
import { DOMAIN_SETTINGS } from '@/helpers/consts';
import { APP_SETTINGS } from '@/app.config';

import Navbar from '@/components/Navbar';
import Call from '@/components/Call';
import TabPanel from '@/components/TabPanel';

import Loading from '@/components/Loading';
import Modals from '@/components/Modals';
import Chat from '@/components/Chat/Chat';
import { Component as vuedal } from 'vuedals';
import { mixinRoles } from '@/mixins';
import HubClientService from '@/services/core/hub';
import chatEventSound from '@/assets/audio_tracks/chat-event.mp3';
import newChatMessageSound from '@/assets/audio_tracks/new-chat-message.mp3';
import CommonModalContainer from '@/components/common/CommonModalContainer.vue';

import { chatService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';
import { createApi } from '@/helpers/api';

export default {
  name: 'App',
  components: {
    CommonModalContainer,
    Loading,
    vuedal,
    Modals,
    Navbar,
    TabPanel,
    Call,
    Chat,
  },
  mixins: [mixinModals, mixinRoles],
  data() {
    return {
      isLoading: false,
      numberOfAppeals: 0,
      userRole: null,
      appealListNew: [],
      appealListInWork: [],
      roles: [],
      token: localStorage.getItem('token'),
      isChatOpen: false,
    };
  },
  computed: {
    domain() {
      // debugger;
      return DOMAIN_SETTINGS[APP_SETTINGS || 'default'];
    },
    isUserLoading() {
      return this.$store.state.Auth.isUserLoading;
    },
    isLogged() {
      const isLoginPage = this.$route.path === '/login';
      const is403Page = this.$route.path === '/403';

      return !!this.$store.state.Auth.token && !is403Page && !isLoginPage;
    },
    isUserRoleAndLogged() {
      return this.checkFeatureAccess({ name: 'Чат', url: '/chat' }) && this.isLogged && this.userRole?.chatAppealLine;
    },
  },
  watch: {
    async isLogged(e) {
      try {
        if (e === true) {
          await this.сheckingUserRole();
          await this.getChatAppealList();
          await this.getChatAppealInWork();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    this.isLoading = true;
    window.addEventListener('storage', this.handleStorageChange);
    try {
      if (this.isLogged) {
        await this.сheckingUserRole();
        await this.getChatAppealList();
        await this.getChatAppealInWork();
      }
    } catch (e) {
      console.error(e);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.handleStorageChange);
  },
  async mounted() {
    this.setFavicon();
    if (this.isLogged) {
      this.initializeSockets();
    }
  },
  methods: {
    handleStorageChange(event) {
      if (event.key === 'token') {
        this.token = event.newValue;
        this.$store.commit('Auth/SET_TOKEN', this.token);
        createApi(this.token);
      }
    },
    playAudioOnEventChat(sound) {
      const audio = new Audio(sound);
      audio.play();
    },
    setFavicon() {
      const favicon = document.getElementById('favicon');
      const faviconUrl = `/${this.domain.favicon}.ico`;
      favicon.href = faviconUrl;
    },
    async сheckingUserRole() {
      const userRoleName = this.$store.state.Auth.role;
      this.roles = await this.getRolesHandler();
      this.userRole = this.roles.find((r) => r.name === userRoleName);
    },
    async getRolesHandler() {
      try {
        const data = await this.$store.dispatch(this.$types.ROLES_FETCH);
        return data;
      } catch (err) {
        if (err.response.status === 401) {
          this.$router.replace('/login');
        }
      }
    },
    async getChatAppealList() {
      try {
        if (this.userRole.chatAppealLine) {
          const data = this.userRole.chatAppealLine === 1
            ? await this.getChatAppealUnassigned()
            : await this.getChatAppealForRole(this.userRole.id);
          this.appealListNew = data.filter((p) => p.personInfo.lastName !== null || p.personInfo.firstName !== null);
          this.numberOfAppeals = this.appealListNew.length;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getChatAppealInWork() {
      const data = await this.chatAppealsInWork();
      this.appealListInWork = data.filter((appeal) => appeal.unreadAmount > 0)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      if (this.appealListInWork.length > 0) {
        this.numberOfAppeals += this.appealListInWork.length;
      }
    },
    async chatAppealsInWork() {
      try {
        const data = await chatService.getInWork();
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список обращений');
        console.warn(err);
        throw err;
      }
    },
    async getChatAppealUnassigned() {
      try {
        const data = await chatService.getUnassigned();
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список обращений');
        console.warn(err);
        throw err;
      }
    },
    async getChatAppealForRole(id) {
      try {
        const data = await chatService.getForRole(id);
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список обращений');
        console.warn(err);
        throw err;
      }
    },
    async initializeSockets() {
      try {
        await HubClientService.init(this.$store.state.Auth.token);
        HubClientService.on('chatAppeal.created', (message) => this.onAddAppeal(message, true));
        HubClientService.on('chatAppeal.moved', (message) => this.onAddAppeal(message, true));
        HubClientService.on('chatAppeal.takeOn', this.onRemoveAppeal);
        HubClientService.on('chat.message', (message) => {
          if (message?.member?.memberType === 'Patient') {
            this.playAudioOnEventChat(newChatMessageSound);
            if (!this.isChatOpen) this.checkAppeals(message);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    checkAppeals(message) {
      const index = this.appealListInWork.findIndex((appeal) => appeal.id === message.params.chatAppealId);
      if (index === -1) {
        this.appealListInWork.push({ id: message.params.chatAppealId });
        this.numberOfAppeals += 1;
      }
    },
    async onAddAppeal(message, playSound = false) {
      if (message) {
        this.numberOfAppeals += 1;
      }
      if (playSound) this.playAudioOnEventChat(chatEventSound);
    },
    async onRemoveAppeal(message) {
      if (message) {
        this.numberOfAppeals -= 1;
      }
    },
    setAmount(value) {
      if (this.isChatOpen && value !== null) this.numberOfAppeals = value;
    },
    removeAppeal(id) {
      const index = this.appealListInWork.findIndex((appeal) => appeal.id === id);
      if (index !== -1) {
        this.appealListInWork.splice(index, 1);
      }
    },
    setChatState(state) {
      this.isChatOpen = state;
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: 'Montserrat';
}
#app {
  width: 100vw;
  min-width: 740px;
  height: 100vh;
  background-color: #f3f3f3;
  color: $black-light;
  font-size: 14px;
  line-height: 17px;
}
</style>
