<template>
  <button
    class="chat-btn"
    @click="openChatModal"
  >
    <b-icon
      variant="primary"
      icon="chat-dots"
      class="chat-btn-icon"
    />
    <span
      v-if="numberOfAppeals > 0"
      class="chat-number-calls"
    >{{ numberOfAppeals }}</span>
  </button>
</template>
<script>
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'Chat',
  props: {
    numberOfAppeals: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    openChatModal() {
      uiService.showModal(MODALS.CHAT_MODAL, {
        name: 'ChatModal',
        actions: {
          unreadAmount: this.setCount,
          removeAppeal: this.removeAppeal,
          chatIsOpen: this.setChatState,
        },
      });
    },
    setCount(count) {
      this.$emit('set-count', count);
    },
    removeAppeal(id) {
      this.$emit('remove-appeal', id);
    },
    setChatState(state) {
      this.$emit('chat-is-open', state);
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-btn {
  position: fixed;
  bottom: 25px;
  right: 55px;
  width: 70px;
  height: 70px;
  border: none;
  background: rgba(0, 0, 0, 0);

  &-icon{
    width: 50px;
    height: 50px;
    transition: 0.5s;
    color: #7a82ec !important;
    &:hover{
    color: rgb(8, 44, 247) !important;
  }
  }
  &-icon:hover {
    transform: scale(1.1);
  }
}
.chat-number-calls{
  position: absolute;
  top:0px;
  right: -5px;
  display: inline-block;
  background: #eeaaaa;
  color: white;
  font-weight: 600;
  text-align: center;
  border-radius: 50%;
  padding: .2em .65em;
}

</style>
