<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="tag.id ? 'Редактирование тэга' : 'Добавление тэга'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Название"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!errors['tagData.name']"
      >
        <b-form-input
          v-model="tagData.name"
          placeholder="Введите название"
          required
          @input="validateField('tagData.name', tagData.name)"
        />
      </b-form-group>

      <b-form-group
        label="Комментарий"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!errors['tagData.comment']"
      >
        <b-form-input
          v-model="tagData.comment"
          placeholder="Введите комментарий"
          required
          @input="validateField('tagData.comment', tagData.comment)"
        />
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onSave"
      >
        Сохранить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { showValidationErrorMessage } from '@/helpers/messages';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { queuesTagEditModalSchema } from '@/validation/zod/queues-tag-edit-modal/queuesTagEditModal.validation';

import Preloader from '@/components/Preloader';

export default {
  name: 'QueuesTagEditModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    tag: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      tagData: {
        id: null,
        name: null,
        comment: null,
      },
      errors: [],
      fieldsToValidate: ['tagData.name', 'tagData.comment'],
    };
  },
  created() {
    if (this.tag.id) this.tagData = { ...this.tag };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types[this.tag.id ? 'QUEUES_TAG_UPDATE' : 'QUEUES_TAG_CREATE'], this.tagData);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        this.afterSave();
      } finally {
        this.isLoading = false;
      }
    },
    validateField(field, value) {
      const error = validateData(field, value, queuesTagEditModalSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },
    makeValidate(fields) {
      const errors = makeValidate(fields, this, queuesTagEditModalSchema);
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
