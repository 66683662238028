import AgreeModal from '@/components/common/modals/AgreeModal';
import AttachPatientModal from '@/components/FamilyPolicies/Modals/AttachPatientModal';
import StatusRequestModal from '@/components/Prices/Modals/StatusRequestModal';
import EditingServicesModal from '@/components/GluingServices/Modals/EditingServicesModal';
import DownloadExcelModal from '@/components/Statistics/Modals/DownloadExcelModal';
import DoctorConsultationsInfoModal from '@/components/Doctors/Modals/DoctorConsultationsInfoModal';
import DoctorUnloadModal from '@/components/Doctors/Modals/DoctorUnloadModal';
import IndemnityPriceTemplateAddModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateAddModal';
import IndemnityPriceTemplateEditModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateEditModal';
import IndemnityPriceTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateDeleteModal';
import IndemnityPriceClinicTemplateAddModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateAddModal';
import IndemnityPriceClinicTemplateEditModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateEditModal';
import IndemnityPriceClinicTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateDeleteModal';
import IndemnityLetterDeleteModal from '@/components/Indemnity/Modals/IndemnityLetterDeleteModal';
import IndemnityLetterAnnulmentModal from '@/components/Indemnity/Modals/IndemnityLetterAnnulmentModal';
import ConsultationHistoryModal from '@/components/Consultations/Modals/ConsultationHistoryModal';
import HistoryConsultationReopeningModal from '@/components/Consultations/Modals/HistoryConsultationReopeningModal';
import ConsultationReopeningModal from '@/components/Consultations/Modals/ConsultationReopeningModal';
import EditBannerModal from '@/components/Settings/BannerManagement/Modals/EditBannerModal';
import BannerPreviewModal from '@/components/Settings/BannerManagement/Modals/BannerPreviewModal';
import ScheduleMakeAnAppointmentModal from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/index';
import ScheduleTemplatesModal from '@/components/Schedule/Modals/ScheduleTemplatesModal';
import ScheduleModal from '@/components/Schedule/Modals/SсheduleModal';
import LaboratoryPromocodeEditModalNew from '@/components/Laboratories/Modals/Promocodes/LaboratoryPromocodeEditModalNew';
import DetachPolicyModal from '@/components/PolicyList/Modal/DetachPolicyModal';
import ScheduleTransferModal from '@/components/Schedule/Modals/ScheduleTransferModal';
import IndemnityModal from '@/components/Indemnity/Modals/IndemnityModal';
import IndemnityTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityTemplateDeleteModal';
import FileModal from '@/components/FileModal';
import ScheduleHistoryModal from '@/components/Schedule/Modals/ScheduleHistoryModal';
import UserClinicDeleteModal from '../../../components/Users/Modals/UserClinicDeleteModal';
import InsurancePolicyExport from '../../../components/InsurancePrograms/Modals/InsurancePolicyExport';
import InsuranceCompanyPhotoModal from '../../../components/InsurancePrograms/Modals/InsuranceCompanyPhotoModal';
import InsuranceImportModal from '../../../components/InsurancePrograms/Modals/InsuranceImportModal';
import InsuranceProgramDeactivateModal from '../../../components/InsurancePrograms/Modals/InsuranceProgramDeactivateModal';
import InsuranceProgramReestrImportModal from '../../../components/InsurancePrograms/Modals/InsuranceProgramReestrImportModal';
import ClinicInfoCardDeleteModal from '../../../components/Clinics/Modals/ClinicInfoCardDeleteModal';
import ClinicsModal from '../../../components/Clinics/Modals/ClinicsModal';
import ClinicPageModal from '../../../components/Clinics/Modals/ClinicPageModal';
import YandexMapModal from '../../../components/Clinics/Modals/YandexMapModal';
import ClinicPackageModal from '../../../components/Clinics/Modals/ClinicPackageModal';
import ClinicResponsibleModal from '../../../components/Clinics/Modals/ClinicResponsibleModal';
import ClinicResponsibleClinicsModal from '../../../components/Clinics/Modals/ClinicResponsibleClinicsModal';
import ClinicEditModal from '../../../components/Clinics/Modals/ClinicEditModal';
import ConsultationControlModal from '../../../components/Consultations/Modals/ConsultationControlModal';
import ConsultationControlExportModal from '../../../components/Consultations/Modals/ConsultationControlExportModal';
import CheckupExportModal from '../../../components/Statistics/Modals/CheckupExportModal';
import AddClinicDownloadFileModal from '../../../components/Clinics/Modals/AddClinicDownloadFileModal';
import FileModalModernNew from '../../../components/FileModalModernNew.vue';
import EditClinicDocument from '../../../components/Clinics/Modals/EditClinicDocument';
import AddClinicDocument from '../../../components/Clinics/Modals/AddClinicDocument';
import DoctorsPinModal from '../../../components/Clinics/Modals/DoctorsPinModal';
import AttachPolicyModal from '../../../components/Patients/Modals/AttachPolicyModal';
import PolicyHasIndemnityModal from '../../../components/Patients/Modals/PolicyHasIndemnityModal';
import PolicyLimitsModal from '../../../components/Patients/Modals/PolicyLimitsModal';
import PatientDeviceModal from '../../../components/Patients/Modals/PatientDeviceModal';
import PatientDeviceDeleteModal from '../../../components/Patients/Modals/PatientDeviceDeleteModal';
import PatientDeleteDopModal from '../../../components/Patients/Modals/PatientDeleteDopModal';
import PatientSetMainUserModal from '../../../components/Patients/Modals/PatientSetMainUserModal';
import PatientDepersonalizateModal from '../../../components/Patients/Modals/PatientDepersonalizateModal';
import PatientConsultationModal from '../../../components/Patients/Modals/PatientConsultationModal';
import PatientInfoModal from '../../../components/Patients/Modals/PatientInfoModal';
import PatientPoliciesModal from '../../../components/Patients/Modals/PatientPoliciesModal';
import PatientReestablishModal from '../../../components/Patients/Modals/PatientReestablishModal';
import PolicyDetachModal from '../../../components/Patients/Modals/PolicyDetachModal';
import PolicyDetachDopModal from '../../../components/Patients/Modals/PolicyDetachDopModal';
import PolicyActivateModal from '../../../components/Patients/Modals/PolicyActivateModal';
import DoctorsModal from '../../../components/Doctors/Modals/DoctorsModal';
import DoctorReestablishModal from '../../../components/Doctors/Modals/DoctorReestablishModal';
import DoctorDeviceModal from '../../../components/Doctors/Modals/DoctorDeviceModal';
import DoctorDeviceDeleteModal from '../../../components/Doctors/Modals/DoctorDeviceDeleteModal';
import DoctorAvatarAddModal from '../../../components/Doctors/Modals/DoctorAvatarAddModal';
import DoctorAvatarDeleteModal from '../../../components/Doctors/Modals/DoctorAvatarDeleteModal';
import DoctorEvaluationModal from '../../../components/Doctors/Modals/DoctorEvaluationModal';
import DoctorInfoModal from '../../../components/Doctors/Modals/DoctorInfoModal';
import ClinicRecoveryModal from '../../../components/Clinics/Modals/ClinicRecoveryModal';
import PricesHistoryModal from '../../../components/Prices/PricesHistoryModal';
import PriceHistoryModal from '../../../components/Prices/PriceHistoryModal';
import PricesImportInClinic from '../../../components/Prices/PricesImportInClinic';
import PricesListViewModal from '../../../components/Prices/PricesListViewModal';
import LaboratoryBranchesModal from '../../../components/Laboratories/Modals/LaboratoryBranchesModal';
import AddBranchImportModal from '../../../components/Laboratories/Modals/Branches/AddBranchImportModal';
import LegalEntityReestrExportModal from '../../../components/LegalEntities/Modals/LegalEntityReestrExportModal';
import RoleEditModal from '../../../components/Roles/Modals/RoleEditModal';
import PageEditModal from '../../../components/Pages/Modals/PageEditModal';
import OrdersHistoryModal from '../../../components/Laboratories/Modals/Orders/OrdersHistoryModal';
import AddPartnerPrices from '../../../components/Laboratories/Modals/Partners/AddPartnerPrices';
import InsuranceSubprogramUploadFile from '../../../components/InsurancePrograms/Modals/InsuranceSubprogramUploadFile';
import ImportBranches from '../../../components/Laboratories/Modals/Branches/ImportBranches';
import QueueDeleteModal from '../../../components/Queues/Modals/QueueDeleteModal';
import QueueConsultationDeleteModal from '../../../components/Queues/Modals/QueueConsultationDeleteModal';
import QueuesTagDeleteModal from '../../../components/Queues/Modals/QueuesTagDeleteModal';
import QueuesTagEditModal from '../../../components/Queues/Modals/QueuesTagEditModal';
import QueueEditModal from '../../../components/Queues/Modals/QueueEditModal';
import SmsSendModal from '../../../components/Sms/Modals/SmsSendModal';
import SmsSendingConfirmation from '../../../components/Sms/Modals/SmsSendingConfirmation';
import SmsSending from '../../../components/Sms/Modals/SmsSending';
import SmsTemplateEditModal from '../../../components/Sms/Modals/SmsTemplateEditModal';
import SmsTemplateDeleteModal from '../../../components/Sms/Modals/SmsTemplateDeleteModal';
import ReferralDutyConsultationImportModal from '../../../components/Consultations/Modals/ReferralDutyConsultationImportModal';

export default [
  {
    name: 'AgreeModal',
    Component: AgreeModal,
  },
  {
    name: 'StatusRequestModal',
    Component: StatusRequestModal,
  },
  {
    name: 'EditingServicesModal',
    Component: EditingServicesModal,
  },

  {
    name: 'UserClinicDeleteModal',
    Component: UserClinicDeleteModal,
  },
  {
    name: 'InsurancePolicyExport',
    Component: InsurancePolicyExport,
  },
  {
    name: 'InsuranceCompanyPhotoModal',
    Component: InsuranceCompanyPhotoModal,
  },
  {
    name: 'InsuranceImportModal',
    Component: InsuranceImportModal,
  },
  {
    name: 'InsuranceProgramDeactivateModal',
    Component: InsuranceProgramDeactivateModal,
  },
  {
    name: 'InsuranceProgramReestrImportModal',
    Component: InsuranceProgramReestrImportModal,
  },

  {
    name: 'DetachPolicyModal',
    Component: DetachPolicyModal,
  },
  {
    name: 'PolicyHasIndemnityModal',
    Component: PolicyHasIndemnityModal,
  },
  {
    name: 'PolicyLimitsModal',
    Component: PolicyLimitsModal,
  },
  {
    name: 'LaboratoryPromocodeEditModal',
    Component: LaboratoryPromocodeEditModalNew,
  },
  {
    name: 'ClinicInfoCardDeleteModal',
    Component: ClinicInfoCardDeleteModal,
  },
  {
    name: 'ClinicsModal',
    Component: ClinicsModal,
  },
  {
    name: 'YandexMapModal',
    Component: YandexMapModal,
  },
  {
    name: 'ClinicPackageModal',
    Component: ClinicPackageModal,
  },
  {
    name: 'ConsultationHistoryModal',
    Component: ConsultationHistoryModal,
  },
  {
    name: 'HistoryConsultationReopeningModal',
    Component: HistoryConsultationReopeningModal,
  },

  {
    name: 'ConsultationReopeningModal',
    Component: ConsultationReopeningModal,
  },
  {
    name: 'EditBannerModal',
    Component: EditBannerModal,
  },
  {
    name: 'BannerPreviewModal',
    Component: BannerPreviewModal,
  },

  {
    name: 'ConsultationControlModal',
    Component: ConsultationControlModal,
  },
  {
    name: 'ConsultationControlExportModal',
    Component: ConsultationControlExportModal,
  },
  {
    name: 'CheckupExportModal',
    Component: CheckupExportModal,
  },
  {
    name: 'ScheduleMakeAnAppointmentModal',
    Component: ScheduleMakeAnAppointmentModal,
  },
  {
    name: 'ScheduleTemplatesModal',
    Component: ScheduleTemplatesModal,
  },
  {
    name: 'ScheduleModal',
    Component: ScheduleModal,
  },
  {
    name: 'ScheduleHistoryModal',
    Component: ScheduleHistoryModal,
  },
  {
    name: 'ScheduleTransferModal',
    Component: ScheduleTransferModal,
  },
  {
    name: 'DownloadExcelModal',
    Component: DownloadExcelModal,
  },
  {
    name: 'AttachPolicyModal',
    Component: AttachPolicyModal,
  },
  {
    name: 'PatientDeviceModal',
    Component: PatientDeviceModal,
  },
  {
    name: 'PatientDeviceDeleteModal',
    Component: PatientDeviceDeleteModal,
  },
  {
    name: 'PatientDeleteDopModal',
    Component: PatientDeleteDopModal,
  },
  {
    name: 'PatientSetMainUserModal',
    Component: PatientSetMainUserModal,
  },
  {
    name: 'PolicyDetachModal',
    Component: PolicyDetachModal,
  },
  {
    name: 'PolicyDetachDopModal',
    Component: PolicyDetachDopModal,
  },
  {
    name: 'PolicyActivateModal',
    Component: PolicyActivateModal,
  },
  {
    name: 'PatientDepersonalizateModal',
    Component: PatientDepersonalizateModal,
  },
  {
    name: 'PatientDeviceDeleteModal',
    Component: PatientDeviceDeleteModal,
  },
  {
    name: 'PatientConsultationModal',
    Component: PatientConsultationModal,
  },
  {
    name: 'PatientInfoModal',
    Component: PatientInfoModal,
  },
  {
    name: 'PatientPoliciesModal',
    Component: PatientPoliciesModal,
  },
  {
    name: 'PatientReestablishModal',
    Component: PatientReestablishModal,
  },
  {
    name: 'AttachPatientModal',
    Component: AttachPatientModal,
  },
  {
    name: 'DoctorDeviceModal',
    Component: DoctorDeviceModal,
  },
  {
    name: 'DoctorDeviceDeleteModal',
    Component: DoctorDeviceDeleteModal,
  },
  {
    name: 'DoctorAvatarAddModal',
    Component: DoctorAvatarAddModal,
  },
  {
    name: 'DoctorAvatarDeleteModal',
    Component: DoctorAvatarDeleteModal,
  },
  {
    name: 'DoctorEvaluationModal',
    Component: DoctorEvaluationModal,
  },
  {
    name: 'DoctorInfoModal',
    Component: DoctorInfoModal,
  },
  {
    name: 'DoctorConsultationsInfoModal',
    Component: DoctorConsultationsInfoModal,
  },
  {
    name: 'DoctorUnloadModal',
    Component: DoctorUnloadModal,
  },
  {
    name: 'AddClinicDownloadFileModal',
    Component: AddClinicDownloadFileModal,
  },
  {
    name: 'FileModalModernNew',
    Component: FileModalModernNew,
  },
  {
    name: 'EditClinicDocument',
    Component: EditClinicDocument,
  },
  {
    name: 'AddClinicDocument',
    Component: AddClinicDocument,
  },
  {
    name: 'DoctorsPinModal',
    Component: DoctorsPinModal,
  },
  {
    name: 'DoctorsModal',
    Component: DoctorsModal,
  },
  {
    name: 'DoctorReestablishModal',
    Component: DoctorReestablishModal,
  },
  {
    name: 'ClinicResponsibleModal',
    Component: ClinicResponsibleModal,
  },
  {
    name: 'ClinicResponsibleClinicsModal',
    Component: ClinicResponsibleClinicsModal,
  },
  {
    name: 'ClinicEditModal',
    Component: ClinicEditModal,
  },
  {
    name: 'ClinicPageModal',
    Component: ClinicPageModal,
  },
  {
    name: 'ClinicRecoveryModal',
    Component: ClinicRecoveryModal,
  },
  {
    name: 'PricesHistoryModal',
    Component: PricesHistoryModal,
  },
  {
    name: 'PriceHistoryModal',
    Component: PriceHistoryModal,
  },
  {
    name: 'PricesImportInClinic',
    Component: PricesImportInClinic,
  },
  {
    name: 'PricesListViewModal',
    Component: PricesListViewModal,
  },
  {
    name: 'LaboratoryBranchesModal',
    Component: LaboratoryBranchesModal,
  },
  {
    name: 'LegalEntityReestrExportModal',
    Component: LegalEntityReestrExportModal,
  },
  {
    name: 'RoleEditModal',
    Component: RoleEditModal,
  },
  {
    name: 'PageEditModal',
    Component: PageEditModal,
  },
  {
    name: 'OrdersHistoryModal',
    Component: OrdersHistoryModal,
  },
  {
    name: 'AddPartnerPrices',
    Component: AddPartnerPrices,
  },
  {
    name: 'AddBranchImportModal',
    Component: AddBranchImportModal,
  },
  {
    name: 'ImportBranches',
    Component: ImportBranches,
  },
  {
    name: 'QueueDeleteModal',
    Component: QueueDeleteModal,
  },
  {
    name: 'QueuesTagDeleteModal',
    Component: QueuesTagDeleteModal,
  },
  {
    name: 'QueuesTagEditModal',
    Component: QueuesTagEditModal,
  },
  {
    name: 'QueueEditModal',
    Component: QueueEditModal,
  },
  {
    name: 'SmsSendModal',
    Component: SmsSendModal,
  },
  {
    name: 'SmsSending',
    Component: SmsSending,
  },
  {
    name: 'SmsSendingConfirmation',
    Component: SmsSendingConfirmation,
  },
  {
    name: 'SmsTemplateEditModal',
    Component: SmsTemplateEditModal,
  },
  {
    name: 'SmsTemplateDeleteModal',
    Component: SmsTemplateDeleteModal,
  },

  {
    name: 'InsuranceSubprogramUploadFile',
    Component: InsuranceSubprogramUploadFile,
  },

  {
    name: 'IndemnityPriceTemplateAddModal',
    Component: IndemnityPriceTemplateAddModal,
  },
  {
    name: 'IndemnityPriceTemplateEditModal',
    Component: IndemnityPriceTemplateEditModal,
  },
  {
    name: 'IndemnityPriceTemplateDeleteModal',
    Component: IndemnityPriceTemplateDeleteModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateAddModal',
    Component: IndemnityPriceClinicTemplateAddModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateEditModal',
    Component: IndemnityPriceClinicTemplateEditModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateDeleteModal',
    Component: IndemnityPriceClinicTemplateDeleteModal,
  },
  {
    name: 'IndemnityLetterDeleteModal',
    Component: IndemnityLetterDeleteModal,
  },
  {
    name: 'IndemnityLetterAnnulmentModal',
    Component: IndemnityLetterAnnulmentModal,
  },

  {
    name: 'QueueConsultationDeleteModal',
    Component: QueueConsultationDeleteModal,
  },
  {
    name: 'IndemnityModal',
    Component: IndemnityModal,
  },
  {
    name: 'IndemnityTemplateDeleteModal',
    Component: IndemnityTemplateDeleteModal,
  },
  {
    name: 'FileModal',
    Component: FileModal,
  },
  {
    name: 'ReferralDutyConsultationImportModal',
    Component: ReferralDutyConsultationImportModal,
  },
];
