import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const smsSendingSchema = z.object({
  phoneNumbers: z.array(
    z.object({
      phone: z.string().min(11).max(12),
      text: z.string().optional(),
    }),
  ).min(1, 'Поле является обязательным'),
  text: z.string().min(1, 'Поле является обязательным').max(1000, 'Поле должно содержать не более 1000 символов'),
});

// eslint-disable-next-line import/prefer-default-export
export const fullSmsSendingSchema = z.object({
  phoneNumbers: z.array(
    z.object({
      phone: z.string().min(11).max(12),
      text: z.string().min(1, 'Поле является обязательным').max(1000, 'Поле должно содержать не более 1000 символов'),
    }),
  ).min(1, 'Поле является обязательным'),
  text: z.string().min(1, 'Поле является обязательным').max(1000, 'Поле должно содержать не более 1000 символов'),
});
