<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Новое СМС-сообщение"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Номер телефона"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!errors['formattedNumber']"
      >
        <masked-input
          v-model="number"
          placeholder="Введите номер"
          :mask="phoneMask"
          required
          class="form-control"
          @input="validateField('formattedNumber', formattedNumber)"
        />
      </b-form-group>

      <b-form-group
        :invalid-feedback="textError"
        :state="!errors['text']"
      >
        <div class="d-flex align-items-center mb-2">
          <label class="col-form-label required">
            Текст сообщения
          </label>

          <b-button
            :variant="isTemplatesShow ? '' : 'primary'"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto"
            size="sm"
            @click="toggleTemplatesShow"
          >
            {{ isTemplatesShow ? 'Скрыть' : 'Шаблон' }}
          </b-button>
        </div>

        <div
          v-if="isTemplatesShow"
          class="mb-5"
        >
          <div class="d-flex align-items-center mb-2">
            <b-form-input
              v-model="templateSearchString"
              type="text"
              debounce="500"
              placeholder="Поиск шаблона"
              size="sm"
              trim
              class="mr-2"
            />

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="() => openSmsTemplateEditModal()"
            >
              Добавить
            </b-button>
          </div>

          <div
            v-if="filteredTemplates.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="template in filteredTemplates"
                  :key="template.id"
                >
                  <b-td class="w-100 table-cell-overflow">
                    <pre class="p-2 m-0">{{ template.text }}</pre>
                  </b-td>
                  <b-td>
                    <div class="d-flex">
                      <b-button
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        title="Редактировать"
                        @click="openSmsTemplateEditModal(template)"
                      >
                        <b-icon icon="pencil" />
                      </b-button>
                      <b-button
                        variant="danger"
                        :type="$const.PRIMARY_BUTTON"
                        class="ml-2"
                        size="sm"
                        title="Удалить"
                        @click="openSmsTemplateDeleteModal(template.id)"
                      >
                        <b-icon icon="trash" />
                      </b-button>
                    </div>
                  </b-td>
                  <b-td>
                    <b-button
                      size="sm"
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      title="Выбрать"
                      :disabled="template.text === text"
                      @click="setSmsText(template.text)"
                    >
                      <b-icon icon="check" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="templateSearchString && !filteredTemplates.length"
            class="ml-2"
          >
            Шаблон не найден
          </small>

          <!-- <div
            v-if="!templateSearchString"
            class="ml-2"
          >
            <small>
              Введите текст шаблона
            </small>
          </div> -->
        </div>

        <b-form-textarea
          v-model="text"
          placeholder="Введите текст"
          rows="4"
          required
          @input="onTextInput"
        />
        <small>
          Осталось символов {{ availableCharacters }}/{{ maxTextLength }}
        </small>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClickSave"
      >
        Отправить
      </b-button>
      <!-- <b-button
        variant="primary"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button> -->
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';
import { showValidationErrorMessage } from '@/helpers/messages';
import MaskedInput from 'vue-text-mask';

import Preloader from '@/components/Preloader';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { smsSendModalSchema } from '@/validation/zod/sms/smsSendModal.validation';

export default {
  name: 'SmsSendModal',
  components: {
    Preloader,
    MaskedInput,
  },
  mixins: [validationMixin],
  props: {
    numberProp: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isTemplatesShow: false,
      templateSearchString: '',
      templates: [],
      phoneMask: ['+', 7, '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
      isOpen: true,
      isSaving: false,
      number: '+',
      text: '',
      maxTextLength: 1000,
      fieldsToValidate: ['text', 'formattedNumber'],
      errors: [],
    };
  },
  computed: {
    formattedNumber() {
      return this.phoneFormatToSave(this.number);
    },
    filteredTemplates() {
      return this.templates.filter((t) => {
        if (this.templateSearchString && !t.text.toLowerCase().includes(this.templateSearchString.toLowerCase())) return false; // search by template from templatesSearchString

        return true;
      });
    },
    availableCharacters() {
      return Math.max(0, this.maxTextLength - this.text.length);
    },
    textError() {
      return this.errors.text ? this.errors.text[0] : null;
    },
  },
  mounted() {
    const messageLocalStorage = localStorage.getItem('send-modal-message');
    if (messageLocalStorage && typeof JSON.parse(messageLocalStorage) !== 'object') localStorage.removeItem('send-modal-message');

    const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
    const message = messagesObject[this.numberProp];
    if (message) {
      try {
        this.text = message;
      } catch (e) {
        this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
      }
    } else {
      this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
    }

    if (this.numberProp) this.number = this.numberProp;

    this.fetchSmsTemplates();
  },
  methods: {
    validateField(field, value) {
      const error = validateData(field, value, smsSendModalSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },

    makeValidate(fields) {
      const errors = makeValidate(fields, this, smsSendModalSchema);
      this.errors = [];
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },

    openSmsTemplateDeleteModal(templateId) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsTemplateDeleteModal',
        props: {
          templateId,
          afterDelete: () => this.fetchSmsTemplates(),
        },
      });
    },
    openSmsTemplateEditModal(template) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsTemplateEditModal',
        props: {
          template,
          afterSaveFunc: () => this.fetchSmsTemplates(),
        },
      });
    },
    setSmsText(value) {
      this.isTemplatesShow = false;
      this.text = value;
      this.onTextInput(value);
    },
    async fetchSmsTemplates() {
      this.templates = await this.$store.dispatch(this.$types.SMS_TEMPLATES_FETCH);
    },
    toggleTemplatesShow() {
      this.isTemplatesShow = !this.isTemplatesShow;
    },
    phoneFormatToSave(phone) {
      if (phone) {
        return phone
          .split(' ')
          .join('')
          .split('(')
          .join('')
          .split('_')
          .join('')
          .split(')')
          .join('')
          .split('-')
          .join('');
      }
      return '';
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onTextInput(value) {
      this.validateField('text', this.text);
      const messageLocalStorage = localStorage.getItem('send-modal-message');
      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      messagesObject[this.numberProp] = value;
      localStorage.setItem('send-modal-message', JSON.stringify(messagesObject));
    },
    async onClickSave() {
      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        await this.$store.dispatch(this.$types.MESSAGES_SEND_MESSAGE, {
          number: this.phoneFormatToSave(this.number),
          text: this.text,
        });

        const messageLocalStorage = localStorage.getItem('send-modal-message');
        const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
        delete messagesObject[this.numberProp];
        localStorage.setItem('send-modal-message', JSON.stringify(messagesObject));

        Bus.$emit('messages:update');
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      } catch (error) {
        console.error(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-overflow {
  max-width: 1px;
}
</style>
