<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xs"
    title="Рассылка СМС-сообщений"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-tabs
        v-model="tabIndex"
      >
        <b-tab
          title="Шаблон на номера"
          class="p-2 border border-top-0"
          @click="onTabChange"
        >
          <b-form-group
            :invalid-feedback="textError"
            :state="!errors['text']"
          >
            <div class="d-flex align-items-center mb-2">
              <label class="col-form-label required">
                Текст сообщения
              </label>
            </div>

            <b-form-textarea
              v-model="text"
              placeholder="Введите текст"
              rows="4"
              required
              @input="onTextInput"
            />
            <small>
              Осталось символов {{ availableCharacters }}/{{ maxTextLength }}
            </small>
          </b-form-group>
          <b-form-group
            label="Импорт номеров"
          >
            <b-form-file
              v-model="importFile"
              accept=".xlsx,"
              plain
            />
            <transition name="fade">
              <div
                v-if="importFile"
                class="mt-2"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  @click="onClickImport"
                >
                  Импортировать
                </b-button>
                <b-table
                  v-if="phoneNumbers"
                  :items="phoneNumbers"
                  :fields="fields"
                  class="mt-3"
                  size="sm"
                />
              </div>
            </transition>
            <div v-if="errors['phoneNumbers']">
              <span class="required-text">
                Поле является обязательным *
              </span>
            </div>
          </b-form-group>
        </b-tab>
        <b-tab
          title="Импорт из файла"
          class="p-2 border border-top-0"
          @click="onTabChange"
        >
          <b-form-group
            label="Импорт номеров"
          >
            <b-form-file
              v-model="importFile"
              accept=".xlsx,"
              plain
            />
            <transition name="fade">
              <div
                v-if="importFile"
                class="mt-2"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  @click="onClickImport"
                >
                  Импортировать
                </b-button>
                <b-table
                  v-if="phoneNumbers"
                  :items="phoneNumbers"
                  :fields="fields2"
                  class="mt-3"
                  size="sm"
                />
              </div>
            </transition>
            <div v-if="errors['phoneNumbers']">
              <span class="required-text">
                Поле является обязательным *
              </span>
            </div>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="openSmsSendingModal"
      >
        Отправить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';

import Preloader from '@/components/Preloader';
import { showValidationErrorMessage } from '@/helpers/messages';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { smsSendingSchema, fullSmsSendingSchema } from '@/validation/zod/sms/smsSending.validation';

export default {
  name: 'SmsSending',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    numberProp: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'phone',
          label: 'Номер телефона',
        },
      ],
      fields2: [
        {
          key: 'phone',
          label: 'Номер телефона',
        },
        {
          key: 'text',
          label: 'Текст сообщения',
        },
      ],
      importFile: null,
      isOpen: true,
      isSaving: false,
      text: '',
      phoneNumbers: null,
      maxTextLength: 1000,
      tabIndex: 0,
      fieldsToValidate: ['text', 'phoneNumbers'],
      errors: [],
    };
  },
  computed: {
    availableCharacters() {
      return Math.max(0, this.maxTextLength - this.text.length);
    },
    validationSchema() {
      return this.tabIndex === 0 ? smsSendingSchema : fullSmsSendingSchema;
    },
    textError() {
      return this.errors.text ? this.errors.text[0] : null;
    },
  },
  mounted() {
    const messageLocalStorage = localStorage.getItem('sending-modal-message');
    if (messageLocalStorage && typeof JSON.parse(messageLocalStorage) !== 'object') localStorage.removeItem('sending-modal-message');

    const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
    const message = messagesObject[this.numberProp];
    if (message) {
      try {
        this.text = message;
      } catch (e) {
        this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
      }
    } else {
      this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
    }

    if (this.numberProp) this.number = this.numberProp;
  },
  methods: {
    validateField(field, value) {
      const error = validateData(field, value, this.validationSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },
    validateForm() {
      this.makeValidate(this.fieldsToValidate);
    },

    makeValidate(fields) {
      const errors = makeValidate(fields, this, this.validationSchema);
      this.errors = [];
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
    onTabChange() {
      this.importFile = null;
      this.phoneNumbers = null;
    },
    async onClickImport() {
      const params = {
        importFile: this.importFile,
      };
      this.phoneNumbers = await this.$store.dispatch(this.$types.SMS_IMPORT_PHONES, params);
      this.validateField('phoneNumbers', this.phoneNumbers);
    },
    openSmsSendingModal() {
      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }

      if (this.tabIndex === 0) {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'SmsSendingConfirmation',
          props: {
            text: this.text,
            onSend: this.onSmsSend,
          },
        });
      } else {
        this.onSmsSend();
      }
    },
    async onSmsSend() {
      await this.$store.dispatch(this.$types.SMS_SENDING, {
        phoneNumbers: this.phoneNumbers,
        text: this.tabIndex === 0 ? this.text : null,
      });

      const messageLocalStorage = localStorage.getItem('sending-modal-message');
      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      delete messagesObject[this.phoneNumbers];
      localStorage.setItem('sending-modal-message', JSON.stringify(messagesObject));

      this.onClose();
      Bus.$emit('messages:update');
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onTextInput(value) {
      this.validateField('text', this.text);
      const messageLocalStorage = localStorage.getItem('sending-modal-message');
      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      messagesObject[this.numberProp] = value;
      localStorage.setItem('sending-modal-message', JSON.stringify(messagesObject));
    },
  },
};
</script>

<style lang="scss" scoped>
.required-text {
  color: red;
  font-size: 13px;
}
</style>
