import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const editBannerModalSchema = z.object({
  form: z.object({
    type: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите тип'),
    category: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите категорию'),
    clientType: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите устройство'),
  }),
  fileHero: z.instanceof(File).nullable().refine((value) => value !== null, 'Выберите файл'),
});

// eslint-disable-next-line import/prefer-default-export
export const editBannerFormModalSchema = z.object({
  form: z.object({
    type: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите тип'),
    category: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите категорию'),
    clientType: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Выберите устройство'),
    fileCloudKey: z.string().nullable().refine((value) => value !== null && value !== '', 'Выберите файл'),
  }),
});
